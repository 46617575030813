import { RightCircleOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect, useContext } from "react";
import "./index.scss";
import iconShop from "../../assets/restaurent.png";
import iconMoto from "../../assets/shipping.png";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import ButtonChangeStatusItem from "./components/ButtonChangeStatusItem";
import { ThemeContext } from "handlers/ThemeContext";
import { Colors } from "utils/colors";

function OrderV2(props) {
  const [countdownDate, setCountdownDate] = useState(new Date());
  const [isNotResponse, setNotResponse] = useState(false);

  const {
    order,
    openModal,
    handleSubmitBom,
    id,
    playSound = () => {},
    saveOrderListNotResponse = () => {},
    orderListNotResponse = [],
    playOrderNOtResponseAgain = () => {},
    turnOffAppSound,
    updateOrderNextStatus,
    itemStatus,
    onServed,
  } = props;
  const findOrderOffSound = orderListNotResponse.find(
    (obj) => obj?.id == order?.id
  );
  const isOffSound =
    findOrderOffSound && findOrderOffSound?.isOffSound ? true : false;
  const timePause =
    findOrderOffSound && findOrderOffSound?.timePause
      ? findOrderOffSound?.timePause
      : null;

  const showDetail = useRef(true);

  const handleClickOrder = () => {
    // callApiGetTurnOffAppSound();
    if (showDetail.current) {
      openModal(itemStatus, order);
    } else {
      showDetail.current = true;
    }
  };

  useEffect(() => {
    let countDownInterVal = setInterval(
      () => setCountdownDate(new Date()),
      1000
    );
    return () => clearInterval(countDownInterVal);
  }, []);

  const timePauseOrder = timePause
    ? timePause + parseInt(turnOffAppSound) * 60000
    : 0;

  let distanceToTimePause =
    timePauseOrder - Date.parse(new Date(countdownDate));

  useEffect(() => {
    if (distanceToTimePause < 0 && isOffSound) {
      playOrderNOtResponseAgain({ order });
    }
  }, [distanceToTimePause]);

  const { t } = useTranslation();

  const { isLightMode } = useContext(ThemeContext);
  return (
    <Draggable
      draggableId={`${order?.cartID}+${order?.created_at}+${itemStatus}`}
      key={`${order?.cartID}+${order?.created_at}+${itemStatus}`}
      index={id}
    >
      {(provided, snapshot) => (
        <span
          key={id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={`order-item
            ${isNotResponse && !isOffSound ? "notResponse" : ""} 
            ${isLightMode ? "order-item-light" : ""}
            `}
            onClick={() => {
              handleClickOrder();
            }}
          >
            {order?.assign_from?.name && order?.status === "pending" && (
              <div className="order-item-moved">
                <RightCircleOutlined />
                {`${t("movedFrom")} ${order?.assign_from?.name}`}
              </div>
            )}
            <div
              className={`order-item-box ${
                isLightMode ? "order-item-box-light" : ""
              }`}
              style={
                {
                  // height: order?.vouchers_applied?.length > 0 ? 'auto' : '145px'
                }
              }
            >
              <div className="flex-center" style={{ marginBottom: 16 }}>
                <div
                  style={{
                    background:
                      Colors[order?.cartID % Colors.length].backgroundColor,
                    height: 32,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                    padding: "0 10px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    gap: 5,
                  }}
                >
                  {order?.type === "QUOTE" ? (
                    <img
                      src={iconShop}
                      alt=""
                      style={{ height: 24, width: 24 }}
                    ></img>
                  ) : (
                    <img
                      src={iconMoto}
                      alt=""
                      style={{ height: 24, width: 24 }}
                    />
                  )}
                  <h3
                    style={{
                      color: Colors[order?.cartID % Colors.length].textColor,
                    }}
                  >
                    {order?.order_number
                      ? ` ${order?.order_number}`
                      : ` ${order?.cartID}`}{" "}
                    {order?.table_name ? `- ${order?.table_name}` : ""}
                  </h3>
                </div>
                <div
                  className={` ${isLightMode ? "text-black" : "text-white"}`}
                >
                  {moment(order?.created_at).format("HH:mm A")}
                </div>
              </div>
              {order?.items.map((item, index) => (
                <div
                  className={`${
                    isLightMode ? "order-item-info-light" : "order-item-info"
                  }`}
                  key={`${item.id}-${index}`}
                >
                  <div className=" flex-sb-c">
                    <span
                      className={` order-item-product-name ${
                        isLightMode ? "text-black" : ""
                      }`}
                    >
                      {item?.product_name}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className={` ${
                          isLightMode ? "text-black" : "text-white"
                        }`}
                      >
                        {item?.quantity ? `x${item?.quantity}` : "x1"}
                      </span>
                      <ButtonChangeStatusItem
                        onClick={(e) => {
                          e?.stopPropagation();
                          updateOrderNextStatus(
                            `${item.id}`,
                            item.status === "sent" ? "cooking" : "ready",
                            item.type
                          );
                        }}
                        onServed={() => onServed(`${item.id}`, item.type)}
                        type={item.status}
                      />
                    </div>
                  </div>
                  {item?.note && (
                    <div>
                      <span
                        className={`order-item-product-note ${
                          isLightMode ? "note-light-mode" : ""
                        }`}
                      >
                        • {item?.note}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </span>
      )}
    </Draggable>
  );
}

export default OrderV2;
