import { customNotification, setLocalStorage } from "utils/CommomFunction";
import { Modal } from "antd";
import axios from "axios";
import Reactotron from "reactotron-react-js";

const REACT_APP_URL = `${process.env.REACT_APP_URL}/graphql`;

const { info } = Modal;

export * from "./callApi";
let cancelTokenSource;

export const callApiWithCancel = async (
  query,
  variables,
  isLogin = true,
  isCancelMode = false
) => {
  const accessToken = localStorage.getItem("access_token") || "";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  if (!accessToken && isLogin) {
    localStorage.clear();
    window.location.href = `${window.location.origin}/login`;
  }
  console.log("Step1", cancelTokenSource);

  // Tạo mới cancel token source
  if (isCancelMode) {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Previous request cancelled");
    }
    cancelTokenSource = axios.CancelToken.source();
  }
  try {
    const result = await axios.post(
      REACT_APP_URL,
      { query, variables },
      { headers, cancelToken: cancelTokenSource?.token }
    );
    Reactotron.apisauce(result);
    if (result.data?.errors && result.data?.errors[0]?.message) {
      if (
        result.data?.errors[0]?.message &&
        result.data?.errors[0]?.extensions?.category == "graphql-authorization"
      ) {
        const popState = localStorage.getItem("popState") || "1";
        if (popState === "1") {
          setLocalStorage("popState", 0);
        }
        let timer = setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 3000);

        return info({
          icon: <></>,
          content: <span>Phiên đăng nhập đã hết hạn.</span>,
          onOk() {
            localStorage.clear();
            window.location.reload();
            clearTimeout(timer);
          },
        });
        // default:
        //   notification('error',result.data?.errors[0]?.message )
      }
    }
    return result.data;
  } catch (e) {
    if (e?.message && e?.message?.includes("Network Error")) {
      customNotification("error", "Hãy kiểm tra lại đường truyền internet");
    }
    if (axios.isCancel(e)) {
    } else {
      customNotification("error", "Có lỗi xảy ra trong quá trình xử lý!");
    }
  }
  return true;
};
